import pregannounce from './preg-announce.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>
        <img src={pregannounce} className="preg-announce" alt="pregAnnounce" />
        </p>

      </header>
    </div>
  );
}

export default App;
